
import GenericMixin from '@/lib/mixins/GenericMixin';
import { getComponent, sleep } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
  },
})
export default class TasqFeedbackV5 extends mixins(GenericMixin) {
  DATA_ENTRY_TAB = 'Data entry';

  HISTORY_TAB = 'Details';

  activeTab = this.DATA_ENTRY_TAB;

  defermentLabelingModal = false;

  resizeLoading = false;

  resizeTrigger: any = null;

  collapseWellStatus = false;

  hideWellActionSection = false;

  resizeWindow() {
    this.resizeLoading = true;
    this.$nextTick(() => {
      this.resizeLoading = false;
    });
  }
}
